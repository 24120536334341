import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppResourceNames} from "./app.component";
import {ErrorNotFoundComponent} from "./component/error-not-found/error-not-found.component";
import {JourneyOverviewComponent} from "./component/journey-viewer/journey-overview.component";
import {AttendantViewerComponent} from "./component/attendant-viewer/attendant-viewer.component";
import {AttendantEditorComponent} from "./component/attendant-editor/attendant-editor.component";
import {StopoverEditorComponent} from "./component/stopover-editor/stopover-editor.component";
import {JourneyEditorComponent} from "./component/journey-editor/journey-editor.component";
import {JourneyCreatorComponent} from "./component/journey-creator/journey-creator.component";
import {JourneyHomeComponent} from "./component/journey-starter/journey-home.component";
import {AttendantRegistrationComponent} from "./component/attendant-registration/attendant-registration.component";
import {ParticipantRegistrationComponent} from "./component/participant-registration/participant-registration.component";
import {ParticipantEditorComponent} from "./component/participant-editor/participant-editor.component";
import {ParticipantViewerComponent} from "./component/participant-viewer/participant-viewer.component";
import {StopoverCreatorComponent} from "./component/stopover-creator/stopover-creator.component";
import {StopoverJoiningComponent} from "./component/stopover-joining/stopover-joining.component";
import {JourneyMapComponent} from "./component/journey-map/journey-map.component";
import {FormTestComponent} from "./component/form/form-test/form-test.component";

const routes: Routes = [
  {
    path: 'form-test',
    component: FormTestComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    component: JourneyHomeComponent,
    pathMatch: 'full'
  },
  {
    path: AppResourceNames.JOURNEY + '/start',
    component: JourneyCreatorComponent,
    pathMatch: 'full'
  },
  {
    path: AppResourceNames.JOURNEY + '/:journeyId',
    component: JourneyOverviewComponent,
    pathMatch: 'full'
  },
  {
    path: AppResourceNames.JOURNEY + '/:journeyId/map',
    component: JourneyMapComponent,
    pathMatch: 'full'
  },
  {
    path: AppResourceNames.JOURNEY + '/:journeyId/edit',
    component: JourneyEditorComponent,
    pathMatch: 'full'
  },
  {
    path: AppResourceNames.JOURNEY + '/:journeyId/' + AppResourceNames.ATTENDANT + '/register',
    component: AttendantRegistrationComponent,
    pathMatch: 'full'
  },
  {
    path: AppResourceNames.JOURNEY + '/:journeyId/' + AppResourceNames.ATTENDANT + '/:attendantId',
    component: AttendantViewerComponent,
    pathMatch: 'full'
  },
  {
    path: AppResourceNames.JOURNEY + '/:journeyId/' + AppResourceNames.ATTENDANT + '/:attendantId/edit',
    component: AttendantEditorComponent,
    pathMatch: 'full'
  },
  {
    path: AppResourceNames.JOURNEY + '/:journeyId/' + AppResourceNames.ATTENDANT + '/:attendantId/' + AppResourceNames.STOPOVER + '/add',
    component: StopoverCreatorComponent,
    pathMatch: 'full'
  },
  {
    path: AppResourceNames.JOURNEY + '/:journeyId/' + AppResourceNames.ATTENDANT + '/:attendantId/' + AppResourceNames.STOPOVER + '/:stopId/edit',
    component: StopoverEditorComponent,
    pathMatch: 'full'
  },
  {
    path: AppResourceNames.JOURNEY + '/:journeyId/' + AppResourceNames.ATTENDANT + '/:attendantId/' + AppResourceNames.STOPOVER + '/:stopId/join',
    component: StopoverJoiningComponent,
    pathMatch: 'full'
  },
  {
    path: AppResourceNames.JOURNEY + '/:journeyId/' + AppResourceNames.PARTICIPANT + '/register',
    component: ParticipantRegistrationComponent,
    pathMatch: 'full'
  },
  {
    path: AppResourceNames.JOURNEY + '/:journeyId/' + AppResourceNames.PARTICIPANT + '/:attendeeId',
    component: ParticipantViewerComponent,
    pathMatch: 'full'
  },
  {
    path: AppResourceNames.JOURNEY + '/:journeyId/' + AppResourceNames.PARTICIPANT + '/:attendeeId/edit',
    component: ParticipantEditorComponent,
    pathMatch: 'full'
  },
  {path: '**', component: ErrorNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
