<label class="form-label">{{label}}</label>
<div class="row g-3">
  <div class="col-8">
    <input [formControl]="formControl"
           type="number" autocomplete="off"
           class="form-control"
           [ngClass]="{'is-valid': isValid, 'is-invalid': isInvalid}"
           (blur)="onBlur($event)">
    <div class="form-text">{{description}}</div>
    <div class="invalid-feedback">{{invalidFeedback}}</div>
  </div>
  <div class="col-2" >
    <button type="button" style="width: 100%"
            class="btn btn-outline-primary"
            (click)="increase()"
            [disabled]="disableIncrease()">
      <i class="bi bi-plus-lg"></i>
    </button>
  </div>
  <div class="col-2">
    <button type="button" style="width: 100%"
            class="btn btn-outline-primary"
            (click)="decrease()"
            [disabled]="disableDecrease()">
      <i class="bi bi-dash-lg"></i>
    </button>
  </div>
</div>
