<div>
  <h4><a [routerLink]="['..']"><i class="bi bi-arrow-left"></i></a></h4>
</div>

<div class="map-container" style="height: calc(100vh - 200px);"
     leaflet
     [leafletOptions]="options"
     (leafletMapReady)="onMapReady($event)"
     (leafletMapMove)="onMapMove($event)"
     (leafletClick)="mapClicked($event)">
</div>
