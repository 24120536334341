import {TextInputConfiguration} from "./base/text-input-component-base";

export class NameInputConfiguration implements TextInputConfiguration {

  label = "Name"
  description = "Den Namen wie du in der Gruppe bekannt bist"

  required = true
  requiredFeedback = "Es muss ein Name angegeben werden"

  minLength = 2
  minLengthFeedback = "Mindestens %minLength% Zeichen eingeben"

  maxLength = 20
  maxLengthFeedback = "Maximal %maxLength% Zeichen erlaubt"

  trimSpaces = true

  static get(): TextInputConfiguration {
    return new NameInputConfiguration()
  }
}
