import {AfterViewInit, Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";
import {TextInputComponentBase} from "../base/text-input-component-base";
import * as Leaflet from "leaflet";
import {LatLngLocation} from "../../../classes/latLngLocation";

@Component({
  selector: 'coordinates',
  templateUrl: './coordinates.component.html',
  styleUrls: ['./coordinates.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoordinatesComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CoordinatesComponent),
      multi: true,
    }
  ]
})
export class CoordinatesComponent extends TextInputComponentBase implements OnInit, AfterViewInit  {

  @Input()
  mapButtonText: string = "Karte"

  @Input()
  modalTitel: string = "Punkt wählen"

  @Input()
  useGPSButton: string = "Nutze GPS"

  @Input()
  invalidPatternFeedback: string = "Coordinates needs to have a format like '47.9872, 9.8723'"

  COORDINATE_PATTERN: RegExp  = new RegExp('^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),\\s*[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)$');

  lat?: number;
  lng?: number;

  map!: Leaflet.Map;

  options = {
    layers: [
      Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      })
    ],
    zoom: 100,
    center: { lat: 47.39432396409528, lng: 8.536281932054637 }
  }

  override ngOnInit(): void {
    super.ngOnInit()
    this.formControl.addValidators(Validators.pattern(this.COORDINATE_PATTERN))
  }

  get invalidMessage(): string {
    if(this.formControl.hasError('required')) {
      return this.requiredFeedback
    }
    if(this.formControl.hasError('pattern')) {
      return this.invalidPatternFeedback
    }
    return this.invalidFeedback
  }

  ngAfterViewInit(): void {
    // Modal Events - https://getbootstrap.com/docs/5.2/components/modal/#events
    const modal = document.getElementById('exampleModal')
    if(modal) {
      modal.addEventListener('show.bs.modal',  () => {
        this.onModalShow()
      })
      modal.addEventListener('shown.bs.modal',  () => {
        this.onModalShown()
      })
      modal.addEventListener('hide.bs.modal',  () => {
        this.onModalHide()
      })
      modal.addEventListener('hidden.bs.modal',  () => {
        this.onModalHidden()
      })
      modal.addEventListener('hidePrevented.bs.modal',  () => {
        this.onModelHidePrevented()
      })
    }
  }

  // Modal Events ------------------------------------------------------------------------------
  onModalShow() {
    let loc: LatLngLocation = LatLngLocation.switzerland();
    const value = this.formControl.getRawValue()
    if(value != null) {
      loc = LatLngLocation.fromString(value)
      this.map.setView(loc)
    } else {
      this.map.setView(LatLngLocation.switzerland(), 7)
    }
  }
  onModalShown() {
    //console.info('onModalShown')
    this.map.invalidateSize()
  }
  onModalHide() {

  }
  onModalHidden() {

  }
  onModelHidePrevented() {

  }
  onCancel() {

  }
  // TODO check all calls regarding state
  onOk() {
    this.formControl.setValue(this.map.getCenter().lat + ", " + this.map.getCenter().lng)
    this._onChange(this.map.getCenter().lat + ", " + this.map.getCenter().lng)
    this._onTouched()
    this.formControl.markAsTouched()
    //this.writeValue(this.map.getCenter().lat + ", " + this.map.getCenter().lng)
  }

  // Map Events --------------------------------------------------------------------------------

  onMapReady($event: Leaflet.Map) {
    this.map = $event;
  }

  onMapCenterChange($event: any) {
    //console.info("onCenterChange")
    //this.writeValue( this.map.getCenter().lat + ", " + this.map.getCenter().lng)
  }

  onMapMove($event: any) {
    //console.info("onMapMove " + this.map.getCenter().lat + ", " + this.map.getCenter().lng)
    //this.writeValue( this.map.getCenter().lat + ", " + this.map.getCenter().lng)
  }

  // Form Events -------------------------------------------------------------------------------

  getLocation() {
    if (navigator.geolocation) {

      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          if (position) {
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;
            this.writeValue(this.lat + ", " + this.lng)
            this.map.setView({lat: this.lat, lng: this.lng}, 16)
          }
        },
        (error: GeolocationPositionError) => {
          console.log(error)
        },
        {
          enableHighAccuracy: true,
          timeout: 5 * 1000 * 10,
          maximumAge: 60 * 1000
        }
      );
    } else {
      console.info("Geolocation is not supported by this browser.");
    }
  }
}
