import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormGroup} from "@angular/forms";

@Component({
  selector: 'form-debug',
  templateUrl: './form-debug.component.html',
  styleUrls: ['./form-debug.component.css']
})
export class FormDebugComponent {

  @Input()
  formGroup!: FormGroup;

  constructor() { }

  get controlStates(): ControlState[] {
    const data: ControlState[] = [];
    const state: ControlState = {
      name: "form",
      value: "",
      status: this.formGroup.status,
      valid: this.formGroup.valid,
      dirty: this.formGroup.dirty,
      pristine: this.formGroup.pristine,
      touched: this.formGroup.touched,
      errors: []
    }
    data.push(state)
    return this.calculateControlStates(this.formGroup, data, "form");
  }


  private calculateControlStates(
    control: FormGroup | FormArray,
    data: ControlState[],
    name: string = 'form'): ControlState[] {

    Object.keys(control.controls).forEach((key: string) => {
      const c = control.get(key);
      if (c instanceof FormGroup || c instanceof FormArray) {
        const state: ControlState = {
          name: name + "." + key,
          value: c.getRawValue(),
          status: c.status,
          valid: c.valid,
          dirty: c.dirty,
          pristine: c.pristine,
          touched: c.touched,
          errors: []
        }
        data.push(state)
        this.calculateControlStates(c, data, name + "." + key);
      } else {
        if(c == null) {
          return data;
        }
        const state: ControlState = {
          name: name + "." + key,
          value: c.getRawValue(),
          status: c.status,
          valid: c.valid,
          dirty: c.dirty,
          pristine: c.pristine,
          touched: c.touched,
          errors: []
        }
        if(c.errors != null) {
          Object.keys(c.errors).forEach(keyError => {
            state.errors.push(keyError)
          });
        }
        data.push(state)
      }
    })
    return data;
  }
}

export interface ControlState {
  name: string,
  valid: boolean
  status: string,
  touched: boolean,
  pristine: boolean,
  dirty: boolean,
  value: string,
  errors: string[]
}
