import { Component } from '@angular/core';

import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import {registerLocaleData} from "@angular/common";
registerLocaleData(localeDe, "de", localeDeExtra);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'journey-web-app';
}

export enum AppResourceNames {
  JOURNEY = "journeys",
  JOURNEY_ID = "journeyId",
  ATTENDANT = "attendants",
  ATTENDANT_ID = "attendantId",
  PARTICIPANT = "attendees",
  PARTICIPANT_ID = "attendeeId",
  STOPOVER = "stops",
  STOP_ID = "stopId"
}
