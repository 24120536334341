import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";

import {AppResourceNames} from "../../app.component";
import {UpdateParticipantRequestDto, LocationDto, ParticipantDto} from "../../service/journey.service";
import {JourneyService} from "../../service/journey.service-impl";
import {NameInputConfiguration} from "../form/name-input-configuration";
import {DefaultEmailInputConfiguration} from "../form/email-input-default-configuration";
import {DefaultPhoneInputConfiguration} from "../form/phone-input-default-configuration";
import {LatLngLocation} from "../../classes/latLngLocation";


@Component({
  selector: 'participant-editor',
  templateUrl: './participant-editor.component.html',
  styleUrls: ['./participant-editor.component.css']
})
export class ParticipantEditorComponent implements OnInit {

  nameInputConfig = NameInputConfiguration.get()
  emailInputConfig = DefaultEmailInputConfiguration.get()
  phoneInputConfig = DefaultPhoneInputConfiguration.get()

  form!: FormGroup;

  participant!: ParticipantDto;

  constructor(
    private formBuilder: FormBuilder,
    private journeyService: JourneyService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit(): void {
    this.loadData();
    this.initForm();
  }

  private loadData() {
    this.route.params.subscribe(params => {
      const journeyId = params[AppResourceNames.JOURNEY_ID];
      const attendeeId = params[AppResourceNames.PARTICIPANT_ID];
      this.journeyService
        .loadParticipant(journeyId, attendeeId)
        .subscribe(response => {
          this.participant = response;
        });
    });
  }

  private initForm() {
    if (this.participant) {
      this.form = this.formBuilder.group({
        //contact: this.attendee.contact,
        name: this.participant.contact.name,
        email: this.participant.contact.email,
        phone: this.participant.contact.phone,
        origin: this.location2coordinateString(this.participant.origin)
      });
    } else {
      console.error("not participant available!")
    }
  }

  submit() {
    const participant: UpdateParticipantRequestDto = {
      id: this.participant.id,
      journeyId: this.participant.journeyId,
      contact: {
        name: this.form.value.name,
        email: this.form.value.email,
        phone: this.form.value.phone
      },
      origin: LatLngLocation.fromString(this.form.value.origin)
    }
    this.journeyService.updateParticipant(participant);
    this.back();
  }


  back() {
    if (this.participant) {
      this.router.navigate([
        AppResourceNames.JOURNEY,
        this.participant.journeyId,
        AppResourceNames.PARTICIPANT,
        this.participant.id
      ]).then(() => {
      });
    }
  }

  coordinates2Location(coordinates: string): LocationDto {
    const splitted = coordinates.split(',')
    return {
      lat: Number.parseFloat(splitted[0].trim()),
      lng: Number.parseFloat(splitted[1].trim())
    }
  }

  location2coordinateString(location: LocationDto | null): string | null {
    if(location == null) {
      return null
    }
    return location.lat + ", " + location.lng
  }
}
