import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {TextInputComponentBase, TextInputConfiguration} from "../base/text-input-component-base";
import {NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators} from "@angular/forms";

@Component({
  selector: 'email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailComponent),
      multi: true,
    },
    {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => EmailComponent),
    multi: true,
    }
  ]
})
export class EmailComponent extends TextInputComponentBase implements OnInit {

  @Input()
  set config(config: EmailInputConfiguration) {
    super._config = config
  }

  private _invalidPatternFeedback?: string
  private _defaultInvalidPatternFeedback: string = "Email has invalid format"
  @Input()
  set invalidPatternFeedback(value: string) {
    this._invalidPatternFeedback = value
  }
  get invalidPatternFeedback() {
    return this._invalidPatternFeedback ?? (this._config?.invalidPatternFeedback ?? this._defaultInvalidPatternFeedback)
  }

  EMAIL_PATTERN: RegExp = new RegExp('^(?!(?:(?:\x22?\x5C[\x00-\x7E]\x22?)|(?:\x22?[^\x5C\x22]\x22?)){255,})(?!(?:(?:\x22?\x5C[\x00-\x7E]\x22?)|(?:\x22?[^\x5C\x22]\x22?)){65,}@)(?:(?:[\x21\x23-\x27\x2A\x2B\x2D\x2F-\x39\x3D\x3F\x5E-\x7E]+)|(?:\x22(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|(?:\x5C[\x00-\x7F]))*\x22))(?:\\.(?:(?:[\x21\x23-\x27\x2A\x2B\x2D\x2F-\x39\x3D\x3F\x5E-\x7E]+)|(?:\x22(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|(?:\x5C[\x00-\x7F]))*\x22)))*@(?:(?:(?!.*[^.]{64,})(?:(?:(?:xn--)?[a-z0-9]+(?:-[a-z0-9]+)*\\.){1,126}){1,}(?:(?:[a-z][a-z0-9]*)|(?:(?:xn--)[a-z0-9]+))(?:-[a-z0-9]+)*)|(?:\\[(?:(?:IPv6:(?:(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){7})|(?:(?!(?:.*[a-f0-9][:\\]]){7,})(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,5})?::(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,5})?)))|(?:(?:IPv6:(?:(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){5}:)|(?:(?!(?:.*[a-f0-9]:){5,})(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,3})?::(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,3}:)?)))?(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:1[0-9]{2})|(?:[1-9]?[0-9]))(?:\\.(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:1[0-9]{2})|(?:[1-9]?[0-9]))){3}))\\]))$', 'i');

  override ngOnInit(): void {
    super.ngOnInit()
    this.formControl.addValidators(Validators.pattern(this.EMAIL_PATTERN))
  }

  get invalidMessage(): string {
    if(this.formControl.hasError('pattern')) {
      return this.invalidPatternFeedback
    }
    return this.invalidFeedback
  }
}

export interface EmailInputConfiguration extends TextInputConfiguration {

  invalidPatternFeedback?: string

}
