export interface FormTime {
  hour: number,
  minute: number
}

export interface FormDate {
  year: number,
  month: number,
  day: number
}

export class JourneyFormDate {

  private date: Date;

  constructor(isoDate?: string) {
    if(isoDate) {
      this.date = new Date(Date.parse(isoDate));
    } else {
      this.date = new Date();
    }
    this.date.setSeconds(0,0)
  }

  toIsoUTCString(): string {
    return this.date.toISOString()
  }

  resetTime() {
    this.date.setHours(0,0,0,0)
  }
  toTimeString() {
    return this.date.getHours() + ":" + this.date.getMinutes()
  }

  getFormDateTime() {
    return {
      date: this.getFormDate(),
      time: this.getFormTime()
    }
  }

  getFormDate(): FormDate {
    return {
      year: this.date.getFullYear(),
      month: this.date.getMonth()+1,
      day: this.date.getDate()
    }
  }
  setFormDate(date: FormDate) {
    this.date.setFullYear(date.year, date.month-1, date.day)
  }

  getFormTime(): FormTime {
    return {
      hour: this.date.getHours(),
      minute: this.date.getMinutes()
    }
  }
  setFormTime(time: FormTime) {
    this.date.setHours(time.hour, time.minute)
  }
}
