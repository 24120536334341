<h1>Erfasse deine Reise</h1>

<form [formGroup]="formGroup" (ngSubmit)="submit()">

  <div class="mb-3">
    <text formControlName="title"
          [config]="titleInputConfig"></text>
  </div>

  <div class="mb-3">
    <text formControlName="description"
          [required]="false"
          label="Beschreibung"
          description="Gib weitere Informationen an - falls nötig."
          [maxLength]="100"
          maxLengthFeedback="Nicht mehr als %maxLength% Zeichen verwenden."
    ></text>
  </div>

  <div class="mb-3">
    <text formControlName="address"
          label="Adresse des Zielorts"
          description="Ort, Strasse, Gebäude oder sonst einen Treffpunkt."
          [maxLength]="100"
          maxLengthFeedback="Nicht mehr als %maxLength% Zeichen verwenden."
    ></text>
  </div>

  <div class="mb-3">
    <coordinates formControlName="coordinates"
                 [required]="false"
                 label="GPS Koordinaten des Treffpunkts"
                 description="Längen- und Breitengrad des Treffpunktes, auf der Karte oder auch von Google Maps kopierbar.">
    </coordinates>
  </div>

  <div class="mb-3">
    <date-time formControlName="dateTime"></date-time>
  </div>

  <div class="mb-3">
    <text formControlName="organizerName" [config]="nameInputConfig"></text>
  </div>

  <div class="mb-3">
    <email formControlName="organizerEmail" [config]="emailInputConfig"></email>
  </div>

  <div class="mb-3">
    <phone formControlName="organizerPhone" [config]="phoneInputConfig"></phone>
  </div>


  <div class="mb-3">
    <div class="row">
      <div class="col text-center">
        <button type="button"
                class="btn btn-outline-secondary w-75"
                (click)="back()">Abbrechen</button>
      </div>
      <div class="col text-center">
        <button type="submit"
                class="btn btn-primary w-75"
                [disabled]="formGroup.invalid"
                [ngClass]="{'invalid': formGroup.invalid}">Erstellen</button>
      </div>
    </div>
  </div>

</form>


<div class="mb-3 text-center pt-5">
  <button class="btn btn-outline-warning w-25" (click)="setTestData()">Set Test Data</button>
</div>

<!--
<form-debug [formGroup]="formGroup"></form-debug>
-->
