<h1>Edit Journey</h1>

<form [formGroup]="form" (ngSubmit)="submit()">

  <div class="mb-3">
    <text formControlName="title"
          [config]="titleInputConfig"></text>
  </div>

  <div class="mb-3">
    <text formControlName="description"
          [required]="false"
          label="Beschreibung"
          description="Gib weitere Informationen an - falls nötig."
          [maxLength]="100"
          maxLengthFeedback="Nicht mehr als %maxLength% Zeichen verwenden."
    ></text>
  </div>

  <div class="mb-3">
    <text formControlName="address"
          label="Adresse des Zielorts"
          description="Ort, Strasse, Gebäude oder sonst einen Treffpunkt."
          [maxLength]="100"
          maxLengthFeedback="Nicht mehr als %maxLength% Zeichen verwenden."
    ></text>
  </div>

  <div class="mb-3">
    <coordinates formControlName="coordinates"
                 [required]="false"
                 label="GPS Koordinaten des Treffpunkts"
                 description="Längen- und Breitengrad des Treffpunktes, auf der Karte oder auch von Google Maps kopierbar.">
    </coordinates>
  </div>

  <div class="mb-3">
    <date-time formControlName="dateTime" [required]="true"></date-time>
  </div>

  <div class="mb-3">
    <text formControlName="organizerName" [config]="nameInputConfig"></text>
  </div>

  <div class="mb-3">
    <email formControlName="organizerEmail" [config]="emailInputConfig"></email>
  </div>

  <div class="mb-3">
    <phone formControlName="organizerPhone" [config]="phoneInputConfig"></phone>
  </div>

  <div class="mb-3">
    <div class="row">
      <div class="col text-center">
        <button type="button"
                class="btn btn-outline-secondary w-75"
                (click)="back()">Back</button>
      </div>
      <div class="col text-center">
        <button type="submit"
                class="btn btn-primary w-75"
                [disabled]="form.valid && form.pristine || form.invalid"
                [ngClass]="{'invalid': form.invalid}">Save</button>
      </div>
    </div>
  </div>

  <!--
  <button type="button" class="btn btn-outline-secondary"
          (click)="back()">Back</button>
  <button type="submit" class="btn btn-primary">Save</button>
  -->
</form>

<!--
<form-debug [formGroup]="form"></form-debug>
-->
