<div>
  <h4><a [routerLink]="['../..']"><i class="bi bi-arrow-left"></i></a></h4>
</div>

<h4>Begleiter</h4>

<ng-template [ngIf]="attendant">
  <div class="position-relative pt-2">
    <h1>{{attendant.name}}</h1>
    <h4>
        <span class="position-absolute end-0">
          <a [routerLink]="['edit']"><i class="bi bi-pencil-square"></i></a>
        </span></h4>
  </div>

  <div class="pt-2">
    <ng-template [ngIf]="attendant.type == CAR">
      <p><i class="bi bi-car-front"></i> {{attendant.capacity}} total Plätze, {{attendant.free}} noch frei</p>
    </ng-template>
    <ng-template [ngIf]="attendant.type == PT">
      <p><i class="bi bi-train-front"></i> Öffentliche Verkehrsmittel</p>
    </ng-template>

    <ng-container *ngIf="attendant.email">
      <p><i class="bi bi-at"></i>&nbsp;<a href="mailto:{{attendant.email}}">{{attendant.email}}</a><p>
    </ng-container>
    <ng-container *ngIf="attendant.phone">
      <p><i class="bi bi-phone"></i> {{attendant.phone}}</p>
    </ng-container>
  </div>

  <div class="mt-4 pt-2 position-relative">
    <h2>
      Reiseroute
    </h2>
    <h4>
      <span class="position-absolute end-0">
        <a [routerLink]="[resourceNames.STOPOVER, 'add']" title="Füge weiteren Stopp hinzu"><i class="bi bi-plus-square"></i></a>
      </span>
    </h4>
  </div>

  <div *ngFor="let stopover of route.stopovers" style="font-size: large">
    <div style="display: flex;">
      <div style="width: 40px; height: 100%; float: left; text-align:center;" class="p-2">
        <i class="bi bi-geo-alt"></i>
      </div>
      <div style="width: auto;" class="p-2">
        <ng-template [ngIf]="stopover.isoDateTime == null">
          <b style="color: red">Zeit?</b> - {{stopover.description}}
        </ng-template>
        <ng-template [ngIf]="stopover.isoDateTime != null">
          <b>{{stopover.isoDateTime | date:'HH:mm' }}</b> - {{stopover.description}}
        </ng-template>
          <ng-template [ngIf]="stopover.location"><i class="bi bi-geo-fill"></i></ng-template>
      </div>
      <div style="width: 80px; padding-left: 10px " class="p-2">
        <a [routerLink]="[resourceNames.STOPOVER, stopover.id, 'edit']"><i class="bi bi-pencil"></i></a> &nbsp;
        <ng-template [ngIf]="stopover.joiners.length > 0 && route.stopovers.length > 1">
          <i class="bi bi-trash3"></i>
        </ng-template>
        <ng-template [ngIf]="stopover.joiners.length == 0 && route.stopovers.length > 1">
          <a role="button" class="text-primary" (click)="removeStopover(stopover.id)" title="Entferne den Stopp"><i class="bi bi-trash3"></i></a>
        </ng-template>
      </div>
    </div>
    <div style="display: flex;">
      <div style="width: 40px; display: flex;">
        <span style="position: relative; height: 100%; border-left: 2px dotted black; left: 50%; margin-left: -1px;"></span>
      </div>
      <div style="width: auto;">
        <ul class="list-group">
          <ng-container *ngIf="stopover.joiners && stopover.joiners.length > 0">
          <!--<div *ngIf="stop.joins && stop.joins.length > 0">-->
            <ng-container *ngFor="let join of stopover.joiners">
              <li class="list-group-item border-0 p-1">
                <i class="bi bi-person-fill"></i> {{join.displayName}}
                <!-- TODO - replace sub-resource attendee of stop -->
                <a role="button" class="text-primary" (click)="removeJoiner(stopover.id, join.participantId)"
                  title="Entferne die Person">
                  <i class="bi bi-trash3"></i></a>
              </li>
            </ng-container>
            <!--</div>-->
          </ng-container>
          <li class="list-group-item border-0 pt-1 pb-3" style="font-size: medium">
            <!-- TODO - replace sub-resource attendee of stop -->
            <ng-template [ngIf]="attendant.free && attendant.free > 0">
              <a class="link-primary" [routerLink]="[resourceNames.STOPOVER, stopover.id,'join']">Person hinzufügen</a>
            </ng-template>
            <ng-template [ngIf]="attendant.free == 0">
              <span class="text-secondary">Person hinzufügen</span>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div style="font-size: large">
    <div style="display: flex">
      <div style="width: 40px; height: 100%; float: left; text-align:center;" class="p-2">
        <i class="bi bi-geo-alt-fill"></i>
      </div>
      <div style="width: auto;" class="p-2">
        <b>{{route.destination.isoDateTime | date:'HH:mm' }}</b> - {{route.destination.description}}
      </div>
    </div>
  </div>

  <h2 class="pt-4">Karte</h2>
  <map-viewer [markers]="markers"></map-viewer>

</ng-template>

<!--
<app-debug [object]="attendant"></app-debug>
-->
