import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {TextInputComponentBase, TextInputConfiguration} from "../base/text-input-component-base";
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextComponent),
      multi: true,
    }
  ]
})
export class TextComponent extends TextInputComponentBase implements OnInit {

  @Input()
  set config(config: TextInputConfiguration) {
    super._config = config
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  get invalidMessage(): string {
    return this.invalidFeedback
  }
}
