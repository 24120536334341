import {Component, Input, OnInit} from "@angular/core";
import {FormComponentBase, GenericInputConfiguration} from "./form-component-base";
import {Validators} from "@angular/forms";

@Component({
  template: ''
})
export abstract class NumberInputComponentBase extends FormComponentBase<number> implements OnInit {

  private _minValue?: number
  @Input()
  set minValue(value: number) {
    this._minValue = value
  }
  get minValue(): number {
    return this._minValue ?? (this._config?.minValue ?? Number.MIN_SAFE_INTEGER)
  }

  private _minValueFeedback?: string
  private _defaultMinValueFeedback: string = 'Value has to be %minValue% or bigger. Current value is %currentValue%'
  @Input()
  set minValueFeedback(value: string) {
    this._minValueFeedback = value
  }
  get minValueFeedback(): string {
    return this._minValueFeedback ?? (this._config?.minValueFeedback ?? this._defaultMinValueFeedback)
  }


  private _maxValue!: number
  @Input()
  set maxValue(value: number) {
    this._maxValue = value
  }
  get maxValue(): number {
    //console.info("Local: " + this._maxValue + ", Config: " + this._config?.maxValue
    //  + ", Default: " + Number.MAX_SAFE_INTEGER + ", RESULT: " + (this._maxValue ?? (this._config?.maxValue ?? Number.MAX_SAFE_INTEGER)))
    return this._maxValue ?? (this._config?.maxValue ?? Number.MAX_SAFE_INTEGER)
  }

  private _maxValueFeedback?: string
  private _defaultMaxValueFeedback: string = 'Value has to %maxValue% or smaller. Current value is %currentValue%'
  @Input()
  set maxValueFeedback(value: string) {
    this._maxValueFeedback = value
  }
  get maxValueFeedback(): string {
    return this._maxValueFeedback ?? (this._config?.maxValueFeedback ?? this._defaultMaxValueFeedback)
  }

  private _label?: string
  @Input()
  set label(value: string) {
    this._label = value
  }
  get label(): string {
    if(this.required) {
      return (this._label ?? (this._config?.label ?? "Text Input")) + "*"
    }
    return this._label ?? (this._config?.label ?? "Text Input")
  }

  private _description?: string
  @Input()
  set description(value: string) {
    this._description = value
  }
  get description(): string {
    return this._description ?? (this._config?.description ?? "")
  }

  override ngOnInit(): void {
    super.ngOnInit()

    if(this.minValue) {
      this.formControl.addValidators(Validators.min(this.minValue))
    }
    if(this.maxValue) {
      this.formControl.addValidators(Validators.max(this.maxValue))
    }
  }


  onBlur(event: FocusEvent) {
    this._onTouched()
  }

  get isValid(): boolean {
    return this.formControl.touched
      && this.formControl.valid
  }

  get isInvalid(): boolean {
    return this.formControl.touched
      && this.formControl.invalid
  }

  get invalidFeedback(): string {
    if(this.formControl.valid) {
      return ''
    }

    if(this.formControl.hasError('required')) {
      return this.requiredFeedback
    }
    if(this.formControl.hasError('min')) {
      return this.minValueFeedback
        .replace('%minValue%', "" + this.minValue)
        .replace('%currentValue%', "" + this.formControl.getRawValue())
    }
    if(this.formControl.hasError('max')) {
      return this.maxValueFeedback
        .replace('%maxValue%', "" + this.maxValue)
        .replace('%currentValue%', "" + this.formControl.getRawValue())
    }

    // Fallback for added error keys
    if (this.formControl.errors != null) {
      return 'Invalid text input with unknown errors: ' + Object.keys(this.formControl.errors).join(", ")
    }
    return 'Invalid input field without errors?!'
  }


  override onChange(value: number | null) {
    if (value != null) {
      if (value > this.maxValue) {
        this.formControl.setValue(this.maxValue)
      }
      if (value < this.minValue) {
        this.formControl.setValue(this.minValue)
      }
    }
  }

}


export interface NumberInputConfiguration extends GenericInputConfiguration {

  // UX

  label?: string

  placeholder?: number

  description?: string


  // Validation

  minValue?: number

  minValueFeedback?: string

  maxValue?: number

  maxValueFeedback?: string

}
