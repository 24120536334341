import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {CreateJourneyRequestDto} from "../../service/journey.service";
import {Router} from "@angular/router";
import {AppResourceNames} from "../../app.component";
import {JourneyService} from "../../service/journey.service-impl";
import {LatLngLocation} from "../../classes/latLngLocation";
import {EventTitleInputConfiguration} from "../form/event-title-input-configuration";
import {NameInputConfiguration} from "../form/name-input-configuration";
import {DefaultEmailInputConfiguration} from "../form/email-input-default-configuration";
import {DefaultPhoneInputConfiguration} from "../form/phone-input-default-configuration";

@Component({
  selector: 'app-journey-creator',
  templateUrl: './journey-creator.component.html',
  styleUrls: ['./journey-creator.component.css']
})
export class JourneyCreatorComponent implements OnInit {

  titleInputConfig = EventTitleInputConfiguration.get()
  nameInputConfig = NameInputConfiguration.get()
  emailInputConfig = DefaultEmailInputConfiguration.get()
  phoneInputConfig = DefaultPhoneInputConfiguration.get()

  formGroup!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private journeyService: JourneyService
  ) {
  }

  ngOnInit(): void {

    const dateTime = {
      time: {
        hour: 14,
        minute: 15,
        second: 0
      },
      date: {

      }
    }
    this.formGroup = this.formBuilder.group({
      title: null,                // mandatory
      description: null,          // optional
      address: null,              // mandatory
      coordinates: null,          // optional
      dateTime: dateTime,             // mandatory
      organizerName: null,             // mandatory
      organizerEmail: null,
      organizerPhone: null
    });
  }

  submit() {
    const date = new Date();
    date.setFullYear(this.formGroup.value.dateTime.date.year)
    date.setMonth(this.formGroup.value.dateTime.date.month-1)
    date.setDate(this.formGroup.value.dateTime.date.day)
    date.setHours(this.formGroup.value.dateTime.time.hour)
    date.setMinutes(this.formGroup.value.dateTime.time.minute)
    date.setSeconds(this.formGroup.value.dateTime.time.second)
    date.setMilliseconds(0)

    const dto: CreateJourneyRequestDto = {
      title: this.formGroup.value.title.trim(),
      description: this.formGroup.value.description,
      address: this.formGroup.value.address.trim(),
      location: this.formGroup.value.coordinates ? LatLngLocation.fromString(this.formGroup.value.coordinates) : null,
      dateTime: date.toISOString(),
      organizer: {
        name: this.formGroup.value.organizerName.trim(),
        email: this.formGroup.value.organizerEmail,
        phone: this.formGroup.value.organizerPhone
      },
    };

    console.info("JourneyCreatorComponent.submit: " + JSON.stringify(dto))
    const id = this.journeyService.createJourney(dto);

    this.router.navigate([AppResourceNames.JOURNEY, id]).then();
  }

  back() {
    this.router.navigate(['']).then();
  }



  setTestData() {
    const date = new Date();
    date.setDate(date.getDate() + 10)

    this.formGroup.setValue({
      title: "Unihockey Trimmbach",
      description: "Rückspiel",
      address: "Turnhalle Sport, Sportstrasse, Bern",
      coordinates: "47.3823014, 8.50880393",
      dateTime: {
        date: {
          "year": date.getFullYear(),
          "month": date.getMonth()+1,
          "day": date.getDate()
        },
        time: {
          "hour": 14,
          "minute": 30,
          "second": 0
        }
      },
      organizerName: "Hans",
      organizerEmail: "hans@email.com",
      organizerPhone: "+41791234567"
    })
  }
}
