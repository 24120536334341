
<div class="container text-center" style="min-height: 250px">
  <div id="carouselExampleControls" class="carousel carousel-dark slide " data-bs-ride="carousel">
    <div class="carousel-inner" style="font-size: x-large; font-style: italic;
          padding-top: 30px; padding-bottom: 30px; padding-left: 70px; padding-right: 70px">
      <div class="carousel-item active">
        "Dank dem Journey Planner fahre ich stets mit einem voll besetztem Auto zum Unihockey-Match meiner Tochter!"
      </div>
      <div class="carousel-item">
        "Dank dem Journey Planner wird der Teamgeist bereits während der Anreise mit den ÖV gefördert!"
      </div>
      <div class="carousel-item">
        "Noch nie war die Organisation zur Anreise zum Match so einfach wie mit dem Journey Planner!"
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</div>

<div class="container text-center">
  <button class="btn btn-primary w-50" (click)="start()"><b>Start</b></button>
</div>

<div class="container pt-5 text-center">
  <h2>Beispiele</h2>
  <div *ngFor="let id of getJourneyIds()">
    <a [routerLink]="[resourceNames.JOURNEY, id]">Reise {{id}}</a>
  </div>
</div>

<!--
<div class="container pt-3 text-center">
  <h2>Entwicklung</h2>
  <p><a [routerLink]="['form-test']">Test Formular</a> mit allen Elementen</p>
</div>
-->
