<div>
  <h4><a [routerLink]="['../..']"><i class="bi bi-arrow-left"></i></a></h4>
</div>

<h4>Teilnehmer</h4>

<ng-template [ngIf]="participant">
  <div class="position-relative pt-2">
    <h1>{{participant.contact.name}}</h1>
    <h4>
        <span class="position-absolute end-0">
          <a [routerLink]="['edit']"><i class="bi bi-pencil-square"></i></a>
        </span></h4>
  </div>

  <div class="pt-2">
    <ng-container *ngIf="participant.contact.email">
      <p><i class="bi bi-at"></i>&nbsp;<a href="mailto:{{participant.contact.email}}">{{participant.contact.email}}</a><p>
    </ng-container>
    <ng-container *ngIf="participant.contact.phone">
      <p><i class="bi bi-phone"></i> {{participant.contact.phone}}</p>
    </ng-container>
    <ng-container *ngIf="participant.origin">
      <p><i class="bi bi-geo-alt"></i> {{participant.origin.lat}}, {{participant.origin.lng}}</p>
    </ng-container>
  </div>

  <ng-container *ngIf="participant.origin">
  <div class="pt-2">
    <map-viewer [markers]="markers"
                [center]="mapCenter"
                [mapHeight]="400">
    </map-viewer>
  </div>
  </ng-container>
</ng-template>

<!--
<app-debug [object]="attendee"></app-debug>
-->
