import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {TextInputComponentBase, TextInputConfiguration} from "../base/text-input-component-base";
import {NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";
import {EmailInputConfiguration} from "../email/email.component";

@Component({
  selector: 'phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneComponent),
      multi: true,
    }
  ]
})
export class PhoneComponent extends TextInputComponentBase implements OnInit {

  @Input()
  set config(config: PhoneInputConfiguration) {
    super._config = config
  }

  //PHONE_PATTERN: RegExp = new RegExp('^(0041|041|\\+41|\\+\\+41|41)?(0|\\(0\\))?([1-9]\\d{1})(\\d{3})(\\d{2})(\\d{2})$');
  CH_MOBILE_PHONE_PATTERN: RegExp = new RegExp('^(0041|041|\\+41|\\+\\+41|41)?\\s?(0|\\(0\\))?(7[5-9])\\s?(\\d{3})\\s?(\\d{2})\\s?(\\d{2})$');

  override ngOnInit(): void {
    super.ngOnInit()
    this.formControl.addValidators(Validators.pattern(this.CH_MOBILE_PHONE_PATTERN))
  }

  get invalidMessage(): string {
    return this.invalidFeedback
  }
}

export interface PhoneInputConfiguration extends TextInputConfiguration {

  patternFeedback?: string
}
