import {Validators} from "@angular/forms";
import {Component, Input, OnInit} from "@angular/core";
import {FormComponentBase, GenericInputConfiguration} from "./form-component-base";

@Component({
  template: ''
})
export abstract class TextInputComponentBase extends FormComponentBase<string> implements OnInit {

  // Validation ----------------------------------------------------------

  private _trimSpaces?: boolean
  @Input()
  set trimSpaces(value: boolean) {
    this._trimSpaces = value
  }
  get trimSpaces(): boolean {
    return this._trimSpaces ?? (this._config?.trimSpaces ?? false)
  }

  private _minLength?: number
  @Input()
  set minLength(value: number) {
    this._minLength = value
  }
  get minLength(): number {
    return this._minLength ?? (this._config?.minLength ?? -1)
  }

  private _minLengthFeedback?: string
  private _defaultMinLengthFeedback: string = 'At least %minLength% characters required. Current length is %currentLength%'
  @Input()
  set minLengthFeedback(value: string) {
    this._minLengthFeedback = value
  }
  get minLengthFeedback(): string {
    return this._minLengthFeedback ?? (this._config?.minLengthFeedback ?? this._defaultMinLengthFeedback)
  }

  private _maxLength?: number
  @Input()
  set maxLength(value: number) {
    this._maxLength = value
  }
  get maxLength(): number {
    return this._maxLength ?? (this._config?.maxLength ?? -1)
  }

  private _maxLengthFeedback?: string
  private _defaultMaxLengthFeedback: string = 'Maximum %maxLength% characters allowed. Current length is %currentLength%'
  @Input()
  set maxLengthFeedback(value: string) {
    this._maxLengthFeedback = value
  }
  get maxLengthFeedback(): string {
    return this._maxLengthFeedback ?? (this._config?.maxLengthFeedback ?? this._defaultMaxLengthFeedback)
  }


  private _patternFeedback?: string
  private _defaultPatternFeedback: string = 'Die Eingabe hat ein ungültiges Format'
  @Input()
  set patternFeedback(value: string) {
    this._patternFeedback = value
  }
  get patternFeedback(): string {
    return this._patternFeedback ?? (this._config?.patternFeedback ?? this._defaultPatternFeedback)
  }

  // UX ------------------------------------------------------------------

  private _label?: string
  @Input()
  set label(value: string) {
    this._label = value
  }
  get label(): string {
    if(this.required) {
      return (this._label ?? (this._config?.label ?? "Text Input")) + "*"
    }
    return this._label ?? (this._config?.label ?? "Text Input")
  }

  private _placeholder?: string
  @Input()
  set placeholder(value: string) {
    this._placeholder = value
  }
  get placeholder(): string {
    return this._placeholder ?? (this._config?.placeholder ?? "")
  }

  private _description?: string
  @Input()
  set description(value: string) {
    this._description = value
  }
  get description(): string {
    return this._description ?? (this._config?.description ?? "")
  }


  override ngOnInit(): void {
    // init defaults
    super.ngOnInit()

    if(this.minLength > 0) {
      this.formControl.addValidators(Validators.minLength(this.minLength))
    }
    if(this.maxLength > 0) {
      this.formControl.addValidators(Validators.maxLength(this.maxLength))
    }

    this.formControl.valueChanges.subscribe(value => {
      // TODO oder direkt die registerOnChange Function subscriben lassen? Siehe unten
      //this._onChange(value)
      // TODO braucht es das oder nicht?
      //this._onTouched()
    })
  }

  // Custom -------------------------------------------------------------------

  onBlur(event: FocusEvent) {
    if(this.trimSpaces) {
      let val: string|null = this.formControl.getRawValue();
      if(val) {
        this.formControl.setValue(val.trim())
      }
    }
    this._onTouched()
  }

  get isValid(): boolean {
    return this.formControl.touched
      && this.formControl.valid
  }

  get isInvalid(): boolean {
    return this.formControl.touched
      && this.formControl.invalid
  }

  get invalidFeedback(): string {
    if(this.formControl.valid) {
      return ''
    }

    if(this.formControl.hasError('required')) {
      return this.requiredFeedback
    }
    if(this.formControl.hasError('minlength')) {

      return this.minLengthFeedback
        .replace('%minLength%', "" + this.minLength)
        .replace('%currentLength%',
          this.formControl.getRawValue()!=null ? "" + this.formControl.getRawValue()?.length : "null")
    }
    if(this.formControl.hasError('maxlength')) {
      return this.maxLengthFeedback
        .replace('%maxLength%', ""+this.maxLength)
        .replace('%currentLength%',
          this.formControl.getRawValue()!=null ? "" + this.formControl.getRawValue()?.length : "null")
    }
    if(this.formControl.hasError('pattern')) {
      return this.patternFeedback
    }

    // Fallback for added error keys
    if (this.formControl.errors != null) {
      return 'Invalid text input with unknown errors: ' + Object.keys(this.formControl.errors).join(", ")
    }
    return 'Invalid input field without errors?!'
  }

}

export interface TextInputConfiguration extends GenericInputConfiguration {

  // UX

  label?: string

  placeholder?: string

  description?: string


  // Validation

  minLength?: number

  minLengthFeedback?: string

  maxLength?: number

  maxLengthFeedback?: string


  // Logic

  trimSpaces?: boolean
}
