import {TextInputConfiguration} from "./base/text-input-component-base";
import {EmailInputConfiguration} from "./email/email.component";
import {PhoneInputConfiguration} from "./phone/phone.component";

export class DefaultPhoneInputConfiguration implements PhoneInputConfiguration {

  readonly required = false

  readonly label = "Mobilnummer (aus der Schweiz)"
  readonly description = "Wird allen Teilnehmer angezeigt für einfache Kontaktaufnahme"

  readonly patternFeedback = "Es muss eine schweizer Mobilnummer sein, bsp. 0791234567 oder +41771234567"

  readonly trimSpaces = true

  static get(): PhoneInputConfiguration {
    return new DefaultPhoneInputConfiguration()
  }
}
