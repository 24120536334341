<div>
  <h4><a [routerLink]="['../..']"><i class="bi bi-arrow-left"></i></a></h4>
</div>

<h1>Teilnehmer Registration</h1>

<form [formGroup]="form" (ngSubmit)="submit()">
  <!--
  <div class="mb-3">
    <form-contact formControlName="contact"></form-contact>
  </div>
  -->

  <div class="pt-2">
    <text formControlName="name" [config]="nameInputConfig"></text>
  </div>

  <div class="pt-2">
    <email formControlName="email" [config]="emailInputConfig"></email>
  </div>

  <div class="pt-2">
    <phone formControlName="phone" [config]="phoneInputConfig"></phone>
  </div>

  <div class="mb-3">
    <coordinates formControlName="origin"
                     [required]="false"
                     label="Ausgangspunkt"
                     description="Längen- und Breitengrad deines Ausgangspunkts zur Anzeige auf der Karte."
    ></coordinates>
  </div>

  <div class="mb-3 pt-4">
    <div class="row">
      <div class="col text-end">
        <button type="button"
                class="btn btn-outline-secondary w-50"
                (click)="back()">Back</button>
      </div>
      <div class="col text-start">
        <button type="submit"
                class="btn btn-primary w-50"
                [disabled]="form.invalid"
                [ngClass]="{'invalid': form.invalid}">Save</button>
      </div>
    </div>
  </div>

</form>

<!--
<form-debug [formGroup]="form"></form-debug>
-->
