import {TextInputConfiguration} from "./base/text-input-component-base";

export class EventTitleInputConfiguration implements TextInputConfiguration {

  readonly label = "Event Name"
  readonly description = ""

  readonly required = true
  readonly requiredFeedback = "Es muss ein Name angegeben werden"

  readonly minLength = 5
  readonly minLengthFeedback = "Mindestens %minLength% Zeichen eingeben"

  readonly maxLength = 100
  readonly maxLengthFeedback = "Maximal %maxLength% Zeichen erlaubt"

  readonly trimSpaces = true

  static get(): TextInputConfiguration {
    return new EventTitleInputConfiguration()
  }
}
