import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from '@angular/platform-browser';
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from "../environments/environment";
import {ErrorNotFoundComponent} from './component/error-not-found/error-not-found.component';
import {JourneyOverviewComponent} from './component/journey-viewer/journey-overview.component';
import {AttendantViewerComponent} from './component/attendant-viewer/attendant-viewer.component';
import {AttendantEditorComponent} from './component/attendant-editor/attendant-editor.component';
import {StopoverEditorComponent} from './component/stopover-editor/stopover-editor.component';
import {JourneyEditorComponent} from './component/journey-editor/journey-editor.component';
import {JourneyCreatorComponent} from './component/journey-creator/journey-creator.component';
import {JourneyHomeComponent} from './component/journey-starter/journey-home.component';
import {DebugComponent} from './component/debug/debug.component';
import {AttendantRegistrationComponent} from './component/attendant-registration/attendant-registration.component';
import {ParticipantRegistrationComponent} from './component/participant-registration/participant-registration.component';
import {ParticipantEditorComponent} from './component/participant-editor/participant-editor.component';
import {ParticipantViewerComponent} from './component/participant-viewer/participant-viewer.component';
import {StopoverCreatorComponent} from './component/stopover-creator/stopover-creator.component';
import {StopoverJoiningComponent} from './component/stopover-joining/stopover-joining.component';
import {FormDebugComponent} from './component/form/form-debug/form-debug.component';
import {FormTestComponent} from "./component/form/form-test/form-test.component";
import {EmailComponent} from './component/form/email/email.component';
import {CoordinatesComponent} from './component/form/coordinates/coordinates.component';
import {PhoneComponent} from './component/form/phone/phone.component';
import {TextComponent} from './component/form/text/text.component';
import { DateTimeComponent } from './component/form/date-time/date-time.component';
import { MapViewerComponent } from './component/form/map-viewer/map-viewer.component';
import {JourneyMapComponent} from "./component/journey-map/journey-map.component";
import { NumberInputComponent } from './component/form/number-input/number-input.component';


@NgModule({
  declarations: [
    AppComponent,
    ErrorNotFoundComponent,
    JourneyOverviewComponent,
    AttendantViewerComponent,
    AttendantEditorComponent,
    StopoverEditorComponent,
    JourneyEditorComponent,
    JourneyCreatorComponent,
    JourneyHomeComponent,
    DebugComponent,
    AttendantRegistrationComponent,
    ParticipantRegistrationComponent,
    ParticipantEditorComponent,
    ParticipantViewerComponent,
    StopoverCreatorComponent,
    StopoverJoiningComponent,
    FormDebugComponent,
    FormTestComponent,
    EmailComponent,
    CoordinatesComponent,
    PhoneComponent,
    TextComponent,
    DateTimeComponent,
    MapViewerComponent,
    JourneyMapComponent,
    NumberInputComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    FormsModule,
    LeafletModule
  ],
  providers: [
    ...environment.providers
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
