<h1>Form Test</h1>

<form [formGroup]="form">

  <div class="pt-2">
    <text       formControlName="title"
                [config]="titleInputConfig">
    </text>
  </div>

  <div class="pt-2">
    <date-time  formControlName="dateTime"
                [dateDisabled]="true">
    </date-time>
  </div>

  <div class="pt-2">
    <text       formControlName="name"
                [config]="nameInputConfig">
    </text>
  </div>

  <div class="pt-2">
    <email      formControlName="email"
                [config]="emailInputConfig">
    </email>
  </div>

  <div class="pt-2">
    <phone formControlName="phone"
           [config]="phoneInputConfig">
    </phone>
  </div>

  <div class="pt-2">
    <coordinates formControlName="coordinates"
                 [required]="false"
                 label="Von wo ich komme"
                 description="GPS Koordinaten Format: 47.987982, 8.910543">
    </coordinates>
  </div>

  <div class="pt-2">
    <ngb-timepicker formControlName="time" [minuteStep]=5>
    </ngb-timepicker>
  </div>

  <div class="pt-2">
    <number-input formControlName="number"
                  [config]="numberInputConfig">
    </number-input>
  </div>

  <!--
  <div class="pt-2">
    <contact formControlName="contact"></contact>
  </div>

  <div class="pt-2">
    <form-contact formControlName="contact"></form-contact>
  </div>
  -->

  <div class="pt-5">
  </div>
</form>



<form-debug [formGroup]="form"></form-debug>

