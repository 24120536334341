import {NumberInputConfiguration} from "./base/number-input-component-base";

export class DefaultCapacityInputConfiguration implements NumberInputConfiguration {

  label = "Anzahl Mitreisende"
  description = "Anzahl freie Sitze"

  minValue = 1
  minValueFeedback = "Mindestens einen freien Platz sollte es geben"

  maxValue = 100
  maxValueFeedback = "Können wirklich mehr als %maxValue% mitreisen?"

  required = true
  requiredFeedback = "Es muss eine Anzahl mitreisende angegeben werden"

  static get(): NumberInputConfiguration {
    return new DefaultCapacityInputConfiguration()
  }
}
