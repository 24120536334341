import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {CreateStopoverRequestDto, JourneyDto} from "../../service/journey.service";
import {JourneyService} from "../../service/journey.service-impl";
import {ActivatedRoute, Router} from "@angular/router";
import {AppResourceNames} from "../../app.component";
import {LatLngLocation} from "../../classes/latLngLocation";
import {JourneyFormDate} from "../../classes/journey-form-date";
import {StopoverDescriptionConfiguration} from "../form/stopover-description-configuration";
import {StopoverCoordinatesConfiguration} from "../form/stopover-coordinates-configuration";

@Component({
  selector: 'stopover-creator',
  templateUrl: './stopover-creator.component.html',
  styleUrls: ['./stopover-creator.component.css']
})
export class StopoverCreatorComponent implements OnInit {

  stopoverInputConfig = StopoverDescriptionConfiguration.get()
  stopoverCoordinatesConfig = StopoverCoordinatesConfiguration.get()

  journeyId!: string
  journey!: JourneyDto

  attendantId!: string

  form!: FormGroup

  constructor(
    private formBuilder: FormBuilder,
    private journeyService: JourneyService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.journeyId = params[AppResourceNames.JOURNEY_ID];
      this.attendantId = params[AppResourceNames.ATTENDANT_ID];
    });

    this.loadJourney()
    this.initForm()
  }

  private loadJourney() {
    if (this.journeyId == null) {
      throw new Error("journeyId is NULL")
    }
    this.journeyService.getJourney(this.journeyId).subscribe(response => {
      this.journey = response;
    });
  }

  private initForm() {
    const dateTime: JourneyFormDate = new JourneyFormDate(this.journey.dateTime)
    console.info(dateTime)

    this.form = this.formBuilder.group({
      dateTime: dateTime.getFormDateTime(),
      description: null,
      location: null,
    });
  }

  submit() {
    const dateTime = new JourneyFormDate(this.journey.dateTime)
    dateTime.setFormTime(this.form.value.dateTime.time)

    const stopover: CreateStopoverRequestDto = {
      attendantId: this.attendantId,
      journeyId: this.journeyId,
      location: LatLngLocation.fromString(this.form.value.location),
      description: this.form.value.description,
      isoDateTime: dateTime.toIsoUTCString()
    }
    this.journeyService.createStopover(stopover);
    this.back();
  }

  back() {
    this.router.navigate([
      AppResourceNames.JOURNEY,
      this.journeyId,
      AppResourceNames.ATTENDANT,
      this.attendantId
    ]).then();
  }

}
