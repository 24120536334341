import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

import {AppResourceNames} from "../../app.component";
import {AttendeeOverviewDto, LocationDto, MarkersDto, ParticipantDto} from "../../service/journey.service";
import {JourneyService} from "../../service/journey.service-impl";

@Component({
  selector: 'participant-viewer',
  templateUrl: './participant-viewer.component.html',
  styleUrls: ['./participant-viewer.component.css']
})
export class ParticipantViewerComponent implements OnInit {

  AppResourceNames = AppResourceNames;

  participant!: ParticipantDto;

  markers: MarkersDto|null = null;

  mapCenter: LocationDto|null = null;

  constructor(
    private journeyService: JourneyService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const journeyId: string = params[AppResourceNames.JOURNEY_ID];
      const participantId: string = params[AppResourceNames.PARTICIPANT_ID];

      this.journeyService.loadParticipant(journeyId, participantId).subscribe(response => {
        this.participant = response;
        this.mapCenter = this.participant.origin
      });

      this.journeyService.getJourneyMarkers(journeyId).subscribe(response => {
        this.markers = response
      });
    });
  }
}
