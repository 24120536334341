<div [formGroup]="formGroup">
  <div class="row">
    <div class="col-sm-8 col-7 col-md-6 col-lg-5">
      <div class="mb-3">
        <label class="form-label " for="dateInput" style="padding-top: 6px">{{label}}*</label>
        <div class="input-group" [ngClass]="{'is-valid': isValid(), 'is-invalid': isInvalid()}">
          <input formControlName="date"
                 id="dateInput"
                 ngbDatepicker
                 navigation="arrows"
                 [minDate]="minDateStruct"
                 [maxDate]="maxDateStruct"
                 [ngClass]="{'is-valid': isValid(), 'is-invalid': isInvalid()}"
                 #d="ngbDatepicker"
                 class="form-control"
                 placeholder="dd/mm/yyyy"
                 aria-describedby="dateHelp"
          />
          <button class="btn btn-outline-secondary bi bi-calendar3"
                  type="button"
                  (click)="d.toggle()"
                  [disabled]="isDateDisabled()">
          </button>
        </div>
        <div id="dateHelp" class="form-text">{{description}}</div>
        <div class="invalid-feedback">{{invalidFeedback}}</div>
      </div>
    </div>
    <div class="col-sm-4 col-5 col-md-6 col-lg-7 ">
      <div class="mb-3">
        <ngb-timepicker formControlName="time" [minuteStep]=5 >
        </ngb-timepicker>
      </div>
    </div>
  </div>
</div>

<!--
<form-debug [formGroup]="formGroup"></form-debug>
-->
