export class LatLngLocation {

  readonly lat: number
  readonly lng: number

  private constructor(lat: number, lng: number) {
    this.lat = lat
    this.lng = lng
  }

  static from(latitude: number|string, longitude: number|string): LatLngLocation {

    const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/
    const regexLon = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/

    let validLat = regexLat.test("" + latitude);
    let validLon = regexLon.test("" + longitude);

    const lat: number = Number.parseFloat("" + latitude)
    const lng: number = Number.parseFloat("" + longitude)

    if(validLat && validLon) {
      return new LatLngLocation(lat, lng)
    }
    throw new Error("Invalid lat/lng: '" + latitude + "' / '" + longitude + "'")
  }

  static fromString(coordinates: string): LatLngLocation {
    const result = coordinates.split(',');
    return LatLngLocation.from(result[0].trim(), result[1].trim())
  }

  static switzerland() {
    return new LatLngLocation(46.798449, 8.231879)
  }

  toString(): string {
    return this.lat + ", " + this.lng
  }
}
