<div class="mb-3">
  <label class="form-label">{{label}}</label>
  <div class="row g-3">
    <div class="col-9">
      <input [formControl]="formControl"
             class="form-control" autocomplete="off"
             [ngClass]="{'is-valid': isValid, 'is-invalid': isInvalid}"
             (blur)="onBlur($event)"
             [placeholder]="placeholder">
      <div class="invalid-feedback">{{invalidMessage}}</div>
    </div>
    <div class="col-3">
      <button type="button" class="btn btn-outline-primary" style="width: 100%" data-bs-toggle="modal" data-bs-target="#exampleModal" >
        {{mapButtonText}}
      </button>
    </div>
    <div class="form-text">{{description}}</div>
  </div>
</div>



<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1"
     aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{modalTitel}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div id="map-wrapper">
          <div id="map-canvas">
            <div class="map-container" style="height: 100%"
                 leaflet
                 [leafletOptions]="options"
                 (leafletMapReady)="onMapReady($event)"
                 (leafletMapMoveEnd)="onMapMove($event)"
                 (leafletCenterChange)="onMapCenterChange($event)">
            </div>
          </div>
          <div id="map-cross"></div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row w-100">
          <div class="col">
            <button type="button" class="btn btn-outline-primary w-100" (click)="getLocation()">{{useGPSButton}}</button>
          </div>

          <div class="col">
            <button type="button" class="btn btn-primary w-100" data-bs-dismiss="modal" (click)="onOk()">OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
