<h1>Zwischenstopp hinzufügen</h1>

<form [formGroup]="form" (ngSubmit)="submit()">

  <div class="pt-2">
    <date-time formControlName="dateTime" [dateDisabled]="true"></date-time>
  </div>

  <div class="pt-0">
    <text formControlName="description" [config]="stopoverInputConfig"></text>
  </div>

  <div class="pt-2">
    <coordinates formControlName="location"></coordinates>
  </div>

  <div class="pt-4">
    <div class="row">
      <div class="col text-end">
        <button type="button"
                class="btn btn-outline-secondary w-50"
                (click)="back()">Back</button>
      </div>
      <div class="col text-start">
        <button type="submit"
                class="btn btn-primary w-50"
                [disabled]="form.invalid"
                [ngClass]="{'invalid': form.invalid}">Save</button>
      </div>
    </div>
  </div>

</form>

<!--
<app-debug [object]="form.getRawValue()"></app-debug>
-->
