import { Component, OnInit } from '@angular/core';
import {AppResourceNames} from "../../app.component";
import {JourneyService} from "../../service/journey.service-impl";
import {Router} from "@angular/router";

@Component({
  selector: 'app-journey-starter',
  templateUrl: './journey-home.component.html',
  styleUrls: ['./journey-home.component.css']
})
export class JourneyHomeComponent implements OnInit {

  resourceNames = AppResourceNames;

  constructor(
    private journeyService: JourneyService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  getJourneyIds(): string[] {
    return this.journeyService.getAllJourneyIds();
  }

  start() {
    this.router.navigate([AppResourceNames.JOURNEY, 'start']).then();
  }
}
