import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NumberInputComponentBase, NumberInputConfiguration} from "../base/number-input-component-base";
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    }
  ]
})
export class NumberInputComponent extends NumberInputComponentBase implements OnInit {

  @Input()
  set config(config: NumberInputConfiguration) {
    this._config = config
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  increase() {
    const current = this.formControl.getRawValue();
    if(current == null) {
      this.formControl.setValue(1);
    } else {
      if(current < this.maxValue) {
        this.formControl.setValue(current + 1)
      }
    }
  }

  disableIncrease(): boolean {
    const current = this.formControl.getRawValue();
    return this.formControl.disabled || !(current == null || current < this.maxValue)
  }

  decrease() {
    const current = this.formControl.getRawValue();
    if(current == null) {
      this.formControl.setValue(this.minValue);
    } else {
      if(current > this.minValue) {
        this.formControl.setValue(current - 1)
      }
    }
  }

  disableDecrease(): boolean {
    const current = this.formControl.getRawValue();
    return this.formControl.disabled || !(current == null || current > this.minValue)
  }

}
