import {Component, OnInit} from '@angular/core';
import {AttendantDto, MarkersDto, RouteDto, TransportType} from "../../service/journey.service";
import {ActivatedRoute} from "@angular/router";
import {AppResourceNames} from "../../app.component";
import {JourneyService} from "../../service/journey.service-impl";


@Component({
  selector: 'app-attendant-viewer',
  templateUrl: './attendant-viewer.component.html',
  styleUrls: ['./attendant-viewer.component.css']
})
export class AttendantViewerComponent implements OnInit {

  resourceNames = AppResourceNames;

  CAR = TransportType.CAR
  PT = TransportType.PUBLIC_TRANSPORT

  journeyId!: string;
  attendantId!: string;

  attendant!: AttendantDto;
  route!: RouteDto;
  markers: MarkersDto|null = null;

  constructor(
    private journeyService: JourneyService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.journeyId = params[AppResourceNames.JOURNEY_ID];
      this.attendantId = params[AppResourceNames.ATTENDANT_ID];
    });
    this.loadAttendant()
    this.getRoute()
    this.getRouteMarkers()
  }

  private loadAttendant() {
    this.journeyService.loadAttendant(this.journeyId, this.attendantId).subscribe(response => {
      this.attendant = response;
    });
  }

  private getRoute() {
    this.journeyService.getRoute(this.journeyId, this.attendantId).subscribe(response => {
      this.route = response;
    });
  }

  private getRouteMarkers() {
    this.journeyService.getRouteMarkers(this.journeyId, this.attendantId, "").subscribe(response => {
      this.markers = response;
    });
  }

  removeJoiner(stopId: string, participantId: string) {
    this.journeyService.deleteJoiner(this.journeyId, this.attendantId, stopId, participantId)
    this.loadAttendant()
    this.getRoute()
  }

  removeStopover(stopId: string) {
    this.journeyService.deleteStopover(this.journeyId, this.attendantId, stopId)
    this.loadAttendant()
    this.getRoute()
  }
}
