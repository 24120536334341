import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {NameInputConfiguration} from "../name-input-configuration";
import {DefaultEmailInputConfiguration} from "../email-input-default-configuration";
import {DefaultPhoneInputConfiguration} from "../phone-input-default-configuration";
import {EventTitleInputConfiguration} from "../event-title-input-configuration";
import {NgbDateStruct, NgbTimeStruct} from "@ng-bootstrap/ng-bootstrap";
import {DefaultCapacityInputConfiguration} from "../number-input-default-configuration";

@Component({
  selector: 'form-test',
  templateUrl: './form-test.component.html',
  styleUrls: ['./form-test.component.css']
})
export class FormTestComponent implements OnInit {

  form!: FormGroup;

  titleInputConfig = EventTitleInputConfiguration.get()
  nameInputConfig = NameInputConfiguration.get()
  emailInputConfig = DefaultEmailInputConfiguration.get()
  phoneInputConfig = DefaultPhoneInputConfiguration.get()
  numberInputConfig = DefaultCapacityInputConfiguration.get()

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    //console.info("initForm")

    const now = new Date()
    //console.info(now.toISOString())
    now.setDate(now.getDate() + 20)

    //console.info(now.toISOString())
    const date: NgbDateStruct = {
      day: now.getDate(),
      month: now.getMonth()+1,
      year: now.getFullYear()
    }
    //console.info(JSON.stringify(date))
    const time: NgbTimeStruct = {
      hour: 14,
      minute: 31,
      second: 0
    }

    this.form = this.formBuilder.group({
      title: "Schulreisli",
      name: "Hans",
      email: "hans@muster.ch",
      phone: "+41793034841",
      dateTime: {date: date, time: time},
      coordinates: null,
      contact: {name: "Marcel", email: "marcel@natz.ch", phone: "077 112 33 44"},
      time: time,
      number: 3
    });

    //this.form.get('dateTime')?.disable()
  }
}
