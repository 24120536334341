import { Component, OnInit } from '@angular/core';
import {UnattendedParticipantListDto} from "../../service/journey.service";
import {JourneyService} from "../../service/journey.service-impl";
import {ActivatedRoute, Router} from "@angular/router";
import {AppResourceNames} from "../../app.component";

@Component({
  selector: 'stopover-joining',
  templateUrl: './stopover-joining.component.html',
  styleUrls: ['./stopover-joining.component.css']
})
export class StopoverJoiningComponent implements OnInit {


  journeyId!: string;
  attendantId!: string;
  stopId!: string;

  unattendedParticipants!: UnattendedParticipantListDto;

  constructor(
    private journeyService: JourneyService,
    private route: ActivatedRoute,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.journeyId = params['journeyId'];
      this.attendantId = params['attendantId'];
      this.stopId = params['stopId'];
    });

    this.journeyService.loadUnattendedParticipants(this.journeyId)
      .subscribe(response => {
        this.unattendedParticipants = response;
      });
  }

  select(attendeeId: string|undefined) {
    if(attendeeId == undefined) {
      return;
    }
    this.journeyService.createJoiner(this.journeyId, this.attendantId, this.stopId, attendeeId);

    this.backToAttendant();
  }

  backToAttendant() {
    this.router.navigate([
      AppResourceNames.JOURNEY,
      this.journeyId,
      AppResourceNames.ATTENDANT,
      this.attendantId
    ]).then();
  }

}
