import {TextInputConfiguration} from "./base/text-input-component-base";
import {EmailInputConfiguration} from "./email/email.component";

export class DefaultEmailInputConfiguration implements EmailInputConfiguration {

  readonly label = "Email"
  readonly description = "Wir für Notifikationen verwendet und ist für andere nicht sichtbar"

  readonly invalidPatternFeedback = "Die Email hat ein ungültiges Format"

  readonly required = false
  readonly requiredFeedback = "Es muss eine Email Adresse angegeben werden"

  readonly maxLength = 100
  readonly maxLengthFeedback = "Die Email kann nicht mehr als %maxLength% Zeichen haben"

  readonly trimSpaces = true

  static get(): TextInputConfiguration {
    return new DefaultEmailInputConfiguration()
  }
}
