import {TextInputConfiguration} from "./base/text-input-component-base";

export class StopoverDescriptionConfiguration implements TextInputConfiguration {

  label = "Ort des Zwischenstopps"
  description = "Adresse, Tram-/Busstation, \"beim Kiosk\", Schulhaus Parkplatz, ..."

  required = true
  requiredFeedback = "Es muss ein Name angegeben werden"

  minLength = 2
  minLengthFeedback = "Mindestens %minLength% Zeichen eingeben"

  maxLength = 100
  maxLengthFeedback = "Maximal %maxLength% Zeichen erlaubt"

  trimSpaces = true

  static get(): TextInputConfiguration {
    return new StopoverDescriptionConfiguration()
  }
}
