<ng-template [ngIf]="journey">

  <div class="position-relative pt-2">
    <h1>{{journey.title}}</h1>
    <h4>
    <span class="position-absolute end-0">
      <a [routerLink]="['edit']"><i class="bi bi-pencil-square"></i></a>
    </span></h4>
  </div>

  <ng-template [ngIf]="journey.description">
    <p style="font-size: smaller">{{journey.description}}</p>
  </ng-template>

  <p><i class="bi bi-calendar"></i> {{journey.dateTime | date:'dd.MM.yyyy HH:mm' }}</p>
  <p><i class="bi bi-geo-alt-fill"></i> {{journey.address}}</p>

  <div class="py-2 position-relative">
    <h4>Karte</h4>
    <a [routerLink]="['map']" >
      <div class="map-container" style="height: 300px"
           leaflet
           [leafletOptions]="options"
           (leafletMapReady)="onMapReady($event)">
      </div>
    </a>
  </div>

  <div class="position-relative pt-2">
    <h4>
      Teilnehmer
      <span class="position-absolute end-0">
        <a [routerLink]="[resourceNames.PARTICIPANT, 'register']"><i class="bi bi-plus-square"></i></a>
        <!--<a [routerLink]="[resourceNames.ATTENDEE, 'call']"><i class="bi bi-arrow-down-left-square"></i></a>-->
      </span>
    </h4>
  </div>

  <div class="list-group">
    <div *ngFor="let participant of journey.participants" class="pt-2">
      <div class="position-relative">
        <i class="bi bi-person-fill"></i>
        {{participant.contact.name}}
        <ng-template [ngIf]="participant.origin"> (map)</ng-template>
        <ng-template [ngIf]="participant.ride"> (ride)</ng-template>
        <a [routerLink]="[resourceNames.PARTICIPANT, participant.id]" class="stretched-link"></a>
      </div>
    </div>
  </div>


  <div class="mt-4 pt-2 position-relative">
    <h4>
      Begleiter
      <span class="position-absolute end-0">
        <a [routerLink]="[resourceNames.ATTENDANT, 'register']"><i class="bi bi-plus-square"></i></a>
      </span>
    </h4>
  </div>
  <div class="list-group">
    <div *ngFor="let attendant of journey.attendants" class="pt-2">
      <div class="position-relative">
        <ng-template [ngIf]="attendant.type == CAR">
          <i class="bi bi-car-front"></i> {{attendant.name}} - {{attendant.free}} freie Plätze ({{attendant.capacity}} total)
        </ng-template>
        <ng-template [ngIf]="attendant.type == PT">
          <i class="bi bi-train-front"></i> {{attendant.name}}
        </ng-template>
        <a [routerLink]="[resourceNames.ATTENDANT, attendant.id]" class="stretched-link"></a>
      </div>
    </div>
  </div>

</ng-template>

