import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {UpdateJourneyRequestDto, LocationDto} from "../../service/journey.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AppResourceNames} from "../../app.component";
import {JourneyService} from "../../service/journey.service-impl";
import {EventTitleInputConfiguration} from "../form/event-title-input-configuration";
import {NameInputConfiguration} from "../form/name-input-configuration";
import {DefaultEmailInputConfiguration} from "../form/email-input-default-configuration";
import {DefaultPhoneInputConfiguration} from "../form/phone-input-default-configuration";

@Component({
  selector: 'app-journey-editor',
  templateUrl: './journey-editor.component.html',
  styleUrls: ['./journey-editor.component.css']
})
export class JourneyEditorComponent implements OnInit {

  titleInputConfig = EventTitleInputConfiguration.get()
  nameInputConfig = NameInputConfiguration.get()
  emailInputConfig = DefaultEmailInputConfiguration.get()
  phoneInputConfig = DefaultPhoneInputConfiguration.get()

  journey!: UpdateJourneyRequestDto;

  form!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private journeyService: JourneyService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit(): void {
    this.loadJourney();
    this.initForm();
  }

  private loadJourney() {
    this.route.params.subscribe(params => {
      this.journeyService.getJourney(params['journeyId']).subscribe(response => {
        this.journey = response;
      });
    });
  }

  private initForm() {
    if (!this.journey) {
      return
    }

    const date = new Date(this.journey.dateTime);
    const dateTime: any =  {
      date: {
        year: date.getFullYear(),
        month: date.getMonth()+1,
        day: date.getDate()
      },
      time: {
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: date.getSeconds()
      }
    }

    const organizer: any = {
      name: this.journey.organizer.name,
      email: this.journey.organizer.email,
      phone: this.journey.organizer.phone,
    }

    this.form = this.formBuilder.group({
      title: new FormControl(this.journey.title),
      description: new FormControl(this.journey.description),
      address: new FormControl(this.journey.address),
      coordinates: new FormControl(this.journey.location?.lat + ", " + this.journey.location?.lng),
      dateTime: dateTime,
      organizerName: this.journey.organizer.name,
      organizerEmail: this.journey.organizer.email,
      organizerPhone: this.journey.organizer.phone,
    });
  }


  submit() {
    if (this.journey) {

      const date = new Date();
      date.setFullYear(this.form.value.dateTime.date.year)
      date.setMonth(this.form.value.dateTime.date.month-1)
      date.setDate(this.form.value.dateTime.date.day)
      date.setHours(this.form.value.dateTime.time.hour)
      date.setMinutes(this.form.value.dateTime.time.minute)
      date.setSeconds(this.form.value.dateTime.time.second)
      date.setMilliseconds(0)

      const dto: UpdateJourneyRequestDto = {
        id: this.journey.id,
        title: this.form.value.title,
        description: this.form.value.description,
        dateTime: date.toISOString(),
        address: this.form.value.address,
        location: this.coordinates2Location(this.form.value.coordinates),
        organizer: {
          name: this.form.value.organizerName,
          email: this.form.value.organizerEmail,
          phone: this.form.value.organizerPhone
        }
      };
      this.journeyService.updateJourney(dto);
    }
    this.back();
  }

  coordinates2Location(coordinates: string): LocationDto {
    const splitted = coordinates.split(',')
    return {
      lat: Number.parseFloat(splitted[0].trim()),
      lng: Number.parseFloat(splitted[1].trim())
    }
  }

  back() {
    if (this.journey) {
      this.router.navigate([AppResourceNames.JOURNEY, this.journey.id]).then();
    }
  }
}
