import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {
  UpdateAttendantRequestDto,
  CreateAttendantRequestDto,
  AttendantDto,
  RegisterParticipantRequestDto,
  CreateJourneyRequestDto,
  UpdateJourneyRequestDto,
  JourneyDto,
  JourneyServiceInterface,
  UpdateParticipantRequestDto,
  UnattendedParticipantListDto,
  UpdateStopoverRequestDto, CreateStopoverRequestDto, ParticipantDto, RouteDto, StopoverDto, MarkersDto
} from "./journey.service";


@Injectable({
  providedIn: 'root'
})
export class JourneyService implements JourneyServiceInterface {
  createJoiner(journeyId: string, attendantId: string, stopoverId: string, participantId: string): void {
  }

  createJourney(journey: CreateJourneyRequestDto): string {
    return "";
  }

  createStopover(stop: CreateStopoverRequestDto): string {
    return "";
  }

  deleteJoiner(journeyId: string, attendantId: string, stopoverId: string, participantId: string): void {
  }

  deleteStopover(journeyId: string, attendantId: string, stopoverId: string): void {
  }

  getAllJourneyIds(): string[] {
    return [];
  }

  getJourney(journeyId: string): Observable<JourneyDto> {
    throw new Error("Not implemented")
  }

  getJourneyMarkers(journeyId: string): Observable<MarkersDto> {
    throw new Error("Not implemented")
  }

  getRoute(journeyId: string, attendantId: string, routeId?: string): Observable<RouteDto> {
    throw new Error("Not implemented")
  }

  loadAttendant(journeyId: string, attendantId: string): Observable<AttendantDto> {
    throw new Error("Not implemented")
  }

  loadUnattendedParticipants(journeyId: string): Observable<UnattendedParticipantListDto> {
    throw new Error("Not implemented")
  }

  loadParticipant(journeyId: string, attendeeId: string): Observable<ParticipantDto> {
    throw new Error("Not implemented")
  }

  readStopover(journeyId: string, attendantId: string, stopoverId: string): Observable<StopoverDto> {
    throw new Error("Not implemented")
  }

  registerAttendant(attendant: CreateAttendantRequestDto): string {
    return "";
  }

  registerParticipant(participant: RegisterParticipantRequestDto): string {
    return "";
  }

  updateAttendant(attendant: UpdateAttendantRequestDto): void {
  }

  updateJourney(journey: UpdateJourneyRequestDto): void {
  }

  updateParticipant(participant: UpdateParticipantRequestDto): void {
  }

  updateStopover(stop: UpdateStopoverRequestDto): void {
  }

  getRouteMarkers(journeyId: string, attendantId: string, routeId: string): Observable<MarkersDto> {
    throw new Error("Not implemented")
  }


}
