<div class="accordion" id="debugAccordion" style="padding-top: 50px">
  <div class="accordion-item">
    <h2 class="accordion-header" id="debugAccordionObjectHeader">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" aria-expanded="true"
              data-bs-target="#debugAccorionObjectItem" aria-controls="debugAccorionObjectItem">
        Debug Object (JSON)
      </button>
    </h2>
    <div id="debugAccorionObjectItem" class="accordion-collapse collapse show"
         aria-labelledby="debugAccordionObjectHeader" data-bs-parent="#debugAccordionObjectHeader">
      <div class="accordion-body">
        <div style="font-size: smaller">
          <pre>{{object | json}}</pre>
        </div>
      </div>
    </div>
  </div>
</div>
