<div class="pt-5">
<div class="container p-2 small rounded border">
  <h5>Form Controls</h5>
  <table class="table">
    <thead>
      <th scope="col" class="p-2">Element</th>
      <th scope="col" class="p-2">Status</th>
      <th scope="col" class="p-2">Dirty</th>
      <th scope="col" class="p-2">Touched</th>
      <th scope="col" class="p-2">Errors</th>
    </thead>

    <tbody>
      <ng-container *ngFor="let control of controlStates">
      <tr>
        <th scope="row">{{control.name}}</th>
        <td>
          {{control.status}}
        </td>
        <td>
          {{control.dirty}}
        </td>
        <td>
          {{control.touched}}
        </td>
        <td>
        <ng-container *ngIf="control.errors.length > 0">
          {{control.errors | json}}
        </ng-container>
        </td>
      </ng-container>
    </tbody>
  </table>

  <h5>Form Data</h5>
  <pre>{{formGroup.getRawValue() | json}}</pre>
</div>
</div>
