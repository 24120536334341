<div class="container-sm" style="max-width: 800px">

  <header class="pt-2 pb-1 mb-4 border-bottom">
    <div class="container">
      <a [routerLink]="['/']" class="text-decoration-none">
        <h3>Journey Planner</h3>
        <!--self organized travelling-->
      </a>
    </div>
  </header>

  <div class="container">
    <router-outlet></router-outlet>
  </div>

  <footer class="border-top mt-4 pt-2">
    <div class="container pt-2">
      <a class="link-primary" href="mailto:journey-feedback@natz.ch">Feedback</a>
    </div>
  </footer>
</div>
