<div class="position-relative">
  <!--<a [routerLink]="['map']" >-->
    <div class="map-container"
         leaflet
         style="height: 300px"
         [leafletOptions]="options"
         (leafletMapReady)="onMapReady($event)">
    </div>
  <!--</a>-->
</div>
