<div>
  <h4><a [routerLink]="['../../..']"><i class="bi bi-arrow-left"></i></a></h4>
</div>

<h1>Mitreisende auswählen</h1>


<ng-template [ngIf]="unattendedParticipants.entities">
  <ng-template [ngIf]="unattendedParticipants.entities.length > 0">
    <p>Folgende Personen wünschen noch eine Mitreisegelegenheit:</p>
    <div style="font-size: large">
    <ul class="list-group">
      <li *ngFor="let onboarder of unattendedParticipants.entities" class="list-group-item border-0 p-1">
        <a role="button" (click)="select(onboarder?.id)"><i class="bi bi-person-fill"></i> {{onboarder?.displayName}}</a>
      </li>
    </ul>
    </div>
    <p class="pt-2">Klicke auf den Namen um der Person eine Mitreisegelegenheit zu geben.</p>
  </ng-template>
  <ng-template [ngIf]="unattendedParticipants.entities.length == 0">
    Alle Personen haben eine Mitreisegelegenheit gefunden.
  </ng-template>
</ng-template>

