import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {JourneyDto, StopoverDto, UpdateStopoverRequestDto} from "../../service/journey.service";
import {JourneyService} from "../../service/journey.service-impl";
import {ActivatedRoute, Router} from "@angular/router";
import {AppResourceNames} from "../../app.component";
import {StopoverDescriptionConfiguration} from "../form/stopover-description-configuration";
import {StopoverCoordinatesConfiguration} from "../form/stopover-coordinates-configuration";
import {JourneyFormDate} from "../../classes/journey-form-date";
import {LatLngLocation} from "../../classes/latLngLocation";

@Component({
  selector: 'app-stopover-editor',
  templateUrl: './stopover-editor.component.html',
  styleUrls: ['./stopover-editor.component.css']
})
export class StopoverEditorComponent implements OnInit {

  stopoverInputConfig = StopoverDescriptionConfiguration.get()
  stopoverCoordinatesConfig = StopoverCoordinatesConfiguration.get()

  form!: FormGroup;

  journeyId!: string
  journey!: JourneyDto
  attendantId!: string
  stopId!: string

  stopover!: StopoverDto
  date!: Date

  constructor(
    private formBuilder: FormBuilder,
    private journeyService: JourneyService,
    private route: ActivatedRoute,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.readIds()
    this.loadJourney()
    this.loadStopover()
    this.initForm();
  }



  private readIds() {
    this.route.params.subscribe(params => {
      this.journeyId = params[AppResourceNames.JOURNEY_ID];
      this.attendantId = params[AppResourceNames.ATTENDANT_ID];
      this.stopId = params[AppResourceNames.STOP_ID];
    });
  }



  private loadJourney() {
    this.journeyService
      .getJourney(this.journeyId)
      .subscribe(response => {
        this.journey = response
      })
  }

  private loadStopover() {
    this.journeyService
        .readStopover(this.journeyId, this.attendantId, this.stopId)
        .subscribe(response => {
          this.stopover = response;
        });
  }

  private initForm() {
    if (!this.stopover) {
      return;
    }

    let dateTime: JourneyFormDate
    if(this.stopover.isoDateTime == null) {
      dateTime = new JourneyFormDate(this.journey.dateTime)
    } else {
      dateTime = new JourneyFormDate(this.stopover.isoDateTime)
    }

    let location: string|null = null
    if(this.stopover.location) {
      location = this.stopover.location.lat + ", " + this.stopover.location.lng
    }

    this.form = this.formBuilder.group({
      dateTime: dateTime.getFormDateTime(),
      description: this.stopover.description,
      location: location
    });


  }

  submit() {
    const date: JourneyFormDate = new JourneyFormDate(this.journey.dateTime)
    date.setFormTime(this.form.value.dateTime.time)

    console.info("Submit: dateTime=" + date.toIsoUTCString())

    const stopover: UpdateStopoverRequestDto = {
      id: this.stopId,
      attendantId: this.attendantId,
      journeyId: this.journeyId,
      description: this.form.value.description,
      location: LatLngLocation.fromString(this.form.value.location),
      isoDateTime: date.toIsoUTCString()
    }

    this.journeyService.updateStopover(stopover);

    this.back();
  }

  back() {
    this.router.navigate([
      AppResourceNames.JOURNEY,
      this.journeyId,
      AppResourceNames.ATTENDANT,
      this.attendantId,
      //AppResourceNames.STOP,
      //this.stopId,
    ]).then();
  }

}
