import {Observable} from "rxjs";

export interface JourneyServiceInterface {

  getAllJourneyIds(): string[];

  /**
   * Journey
   * @param journey
   */
  createJourney(journey: CreateJourneyRequestDto): string;

  getJourney(journeyId: string): Observable<JourneyDto>;

  getJourneyMarkers(journeyId: string): Observable<MarkersDto>;

  updateJourney(journey: UpdateJourneyRequestDto): void;

  /**
   * Attendee
   */
  registerParticipant(participant: RegisterParticipantRequestDto): string;

  loadParticipant(journeyId: string, attendeeId: string): Observable<ParticipantDto>;

  updateParticipant(participant: UpdateParticipantRequestDto): void;

  /**
   * Attendant
   */
  registerAttendant(attendant: CreateAttendantRequestDto): string;

  loadAttendant(journeyId: string, attendantId: string): Observable<AttendantDto>;

  updateAttendant(attendant: UpdateAttendantRequestDto): void

  getRoute(journeyId: string, attendantId: string, routeId: string): Observable<RouteDto>

  getRouteMarkers(journeyId: string, attendantId: string, routeId: string): Observable<MarkersDto>

  /**
   * Create a stopover (as part of a route of an attendant of a journey.
   * @param stop
   */
  createStopover(stop: CreateStopoverRequestDto): string;

  /**
   * To visualize and edit a stopover
   *
   * @param journeyId
   * @param attendantId
   * @param stopoverId
   */
  readStopover(journeyId: string, attendantId: string, stopoverId: string): Observable<StopoverDto>
  updateStopover(stop: UpdateStopoverRequestDto): void;

  deleteStopover(journeyId: string, attendantId: string, stopoverId: string): void;

  /**
   * JOINERS
   */
  loadUnattendedParticipants(journeyId: string): Observable<UnattendedParticipantListDto>;

  createJoiner(journeyId: string, attendantId: string, stopoverId: string, participantId: string): void;

  deleteJoiner(journeyId: string, attendantId: string, stopoverId: string, participantId: string): void;
}


// ------------------------------------------------- JOURNEY

/**
 * To cr
 */
export interface CreateJourneyRequestDto {
  title: string;
  description: string | null;
  dateTime: string;   // ISO String UTC
  address: string | null;
  location: LocationDto | null
  organizer: ContactDto
}

/**
 * To edit a journey (excluded: attendees, attendants)
 */
export interface UpdateJourneyRequestDto {
  id: string;
  title: string;
  description: string | null;
  dateTime: string;   // ISO String UTC
  address: string | null;
  location: LocationDto | null
  organizer: ContactDto
}

/**
 * To show the journey overview page (read-only)
 */
export interface JourneyDto {
  id: string;
  title: string;
  description: string | null;
  dateTime: string;
  address: string | null;
  location: LocationDto | null;
  organizer: ContactDto;
  participants: ParticipantDto[];
  attendants: AttendantDto[];
}

export interface DestinationDto {
  description: string | null
  location: LocationDto | null;
  isoDateTime: string | null
}

export interface LocationDto {
  lat: number;
  lng: number;
}

export interface ContactDto {
  name: string;
  email: string | null;
  phone: string | null;
}

export interface ParticipantDto {
  id: string
  journeyId: string
  state: string | null
  contact: ContactDto
  origin: LocationDto | null
  ride?: RideDto | null
}

export interface RideDto {
  stopover: {
    attendantId: string
    routeId: string | null
    stopoverId: string
  }
}



// ------------------------------------------------- ATTENDANTS / DRIVERS

export enum TransportType {
  CAR = <any>"CAR",
  PUBLIC_TRANSPORT = <any>"PT",
  UNKNOWN = <any>"UNKNOWN"
}

/**
 * To write data: to create a new complete attendant
 */
export interface CreateAttendantRequestDto {
  journeyId: string
  name: string
  email: string
  phone: string
  origin: {
    location: LocationDto | null,
    description: string | null
  }
  type: TransportType
  capacity?: number
}

export interface AttendantDto {
  id: string
  journeyId: string
  name: string;
  email: string | null
  phone: string | null
  type: TransportType
  capacity?: number
  free?: number
}

/**
 * To load and store data:
 *  - to show the editor form
 *  - to write a minimal attendant to backend
 */
export interface UpdateAttendantRequestDto {
  id: string;
  journeyId: string
  name: string
  email: string
  phone: string
  type?: TransportType
  capacity?: number
}


// ------------------------------------------------ ATTENDANT - ROUTE
export interface RouteDto {
  id: string;
  attendantId: string
  journeyId: string
  stopovers: StopoverDto[]
  destination: DestinationDto
}


// ------------------------------------------------- ATTENDANT - ROUTE - STOPOVER

export interface CreateStopoverRequestDto {
  journeyId: string,
  attendantId: string,
  location: LocationDto|null
  description: string|null;
  isoDateTime: string|null;
}

export interface StopoverDto {
  id: string
  attendantId: string,
  journeyId: string,
  location: LocationDto | null
  description: string | null
  isoDateTime: string | null
  joiners: JoinerDto[]
}

export interface UpdateStopoverRequestDto {
  id: string
  attendantId: string
  journeyId: string
  location: LocationDto
  isoDateTime: string|null
  description: string|null
}

export interface DeleteStopoverRequestDto {
  id: string;
  routeId: string
}

// ------------------------------------------------- ATTENDANT - ROUTE - STOPOVER - JOINER

export interface JoinerDto {
  participantId: string;
  displayName: string;
}

export interface UnattendedParticipantListDto {
  entities: [
    {
      id: string; // attendee id
      displayName: string;
    }?
  ]
}


// ------------------------------------------------- ATTENDEE / PARTICIPANTS

/**
 * Load and store Attendee ("Call Attendee" has no DTO)
 */
export interface RegisterParticipantRequestDto {
  journeyId: string;
  contact: ContactDto;
  origin: LocationDto | null;
}

export interface AttendeeOverviewDto {
  id: string;
  journeyId: string;
  contact: ContactDto
  origin: LocationDto | null;
}

export interface UpdateParticipantRequestDto {
  id: string;
  journeyId: string;
  contact: ContactDto
  origin: LocationDto | null;
}

export interface AttendeeConfirmationDto {
  journeyId: string,
  id: string;
  name: string;
  email: string;
  phone: string;
}

// -------------------------------------------------------- MAP
export interface MarkersDto {
  destination: DestinationMarker
  participants: ParticipantMarker[]
  stopovers: StopoverMarker[]
}
export interface DestinationMarker {
  location: LocationDto|null
  description: string|null
}
export interface ParticipantMarker {
  location: LocationDto
  description: string
  state: string
}
export interface StopoverMarker {
  location: LocationDto|null
  description: string|null // name of attendant
  dateTime: string|null
}


